import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import mainReducer from './mainSlice'
import cartReducer from './cartSlice'
import loginReducer from './loginSlice'
import { loadState, saveState } from './localStorage'

const saveStateMiddleware = (store: any) => (next: any) => (action: any) => {
  let result = next(action);
  saveState(store.getState())
  return result;
}

const store = configureStore({
  reducer: {
    main: mainReducer,
    cart: cartReducer,
    login: loginReducer
  },
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveStateMiddleware),
})

export type AppDispatch = typeof store.dispatch

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof store.getState>

export default store;
