import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store'

// Define App reducer

interface AppState {
  token?: string,
}

const initialState: AppState = {
  token: undefined,
}

export const loginSlice = createSlice({
  name: 'login',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
  },
})

export const { setToken } = loginSlice.actions;


export const getToken = (state: RootState) => state.login.token

export default loginSlice.reducer